import { Routes, Route, Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "../Admin/Component/Loader";
import Error404 from "../Main/Pages/Error404";
import HomeRoute from "../Admin/Pages/HomeRoute";

const admin_prefix = process.env.REACT_APP_ADMIN_ROUTE_PREFIX;

const AdminLayout = lazy(() => import("../HOC/AdminLayout"));
const OpenAdmin = lazy(() => import("../HOC/OpenAdmin"));
const Login = lazy(() => import("../Admin/Auth/Login"));
const ForgetPassword = lazy(() => import("../Admin/Auth/ForgetPassword"));
const ResetPassword = lazy(() => import("../Admin/Auth/ResetPassword"));
const Dashboard = lazy(() => import("../Admin/Pages/Dashboard"));
const Profile = lazy(() => import("../Admin/Pages/Profile/Profile"));
const Logout = lazy(() => import("../Admin/Auth/Logout"));

const RolesList = lazy(() => import("../Admin/Pages/Masters/Roles/RolesList"));
const RolePermission = lazy(() =>
  import("../Admin/Pages/Masters/Roles/RolePermission")
);

const UsersList = lazy(() => import("../Admin/Pages/Masters/Users/UsersList"));

const CmsList = lazy(() => import("../Admin/Pages/Masters/Cms/CmsList"));
const UpdateCms = lazy(() => import("../Admin/Pages/Masters/Cms/UpdateCms"));
const BannersList = lazy(() =>
  import("../Admin/Pages/Masters/Banners/BannersList")
);

const ProductList = lazy(() =>
  import("../Admin/Pages/Masters/Products/ProductList")
);
const AddProduct = lazy(() =>
  import("../Admin/Pages/Masters/Products/AddProduct")
);
const EditProduct = lazy(() =>
  import("../Admin/Pages/Masters/Products/EditProduct")
);

const MyNotifications = lazy(() =>
  import("../Admin/Pages/Profile/MyNotifications")
);

const GeneralSettings = lazy(() =>
  import("../Admin/Pages/SiteSetting/GeneralSettings")
);

const CountryList = lazy(() =>
  import("../Admin/Pages/location/Country/CountryList")
);
const StateList = lazy(() => import("../Admin/Pages/location/State/StateList"));
const CityList = lazy(() => import("../Admin/Pages/location/City/CityList"));
const AreaList = lazy(() => import("../Admin/Pages/location/Area/AreaList"));
const EmployeeList = lazy(() =>
  import("../Admin/Pages/Employees/EmployeeList")
);
const EmployeeAdd = lazy(() => import("../Admin/Pages/Employees/EmployeeAdd"));
const DistributorList = lazy(() =>
  import("../Admin/Pages/Distributors/DistributorList")
);
const DistributorAdd = lazy(() =>
  import("../Admin/Pages/Distributors/DistributorAdd")
);
const AttendanceStatus = lazy(() =>
  import("../Admin/Pages/Masters/AttendanceStatus/AttendanceStatus")
);
const Attendance = lazy(() => import("../Admin/Pages/Attendance/Attendance"));
const MonthlyAttendance = lazy(() =>
  import("../Admin/Pages/Attendance/MonthlyAttendance")
);
const DistributorPurchaseList = lazy(() =>
  import("../Admin/Pages/Transactions/DistributorPurchaseList")
);
const EmployeePurchaseList = lazy(() =>
  import("../Admin/Pages/Transactions/EmployeePurchaseList")
);
const EmployeeSalesList = lazy(() =>
  import("../Admin/Pages/Transactions/EmployeeSalesList")
);
const DistributorOrder = lazy(() =>
  import("../Admin/Pages/Transactions/DistributorOrder")
);
const ClosingStockList = lazy(() =>
  import("../Admin/Pages/Transactions/ClosingStockList")
);
const Units = lazy(() => import("../Admin/Pages/Masters/Units/Units"));
const RegionList = lazy(() =>
  import("../Admin/Pages/location/Region/RegionList")
);
const ZoneList = lazy(() => import("../Admin/Pages/location/Zone/ZoneList"));
const AdminMonthlyAttendance = lazy(() => import("../Admin/Pages/Attendance/AdminMonthlyAttendance"));

export default function AdminRoutes() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<HomeRoute />} />
          <Route
            exact
            path={admin_prefix}
            element={<OpenAdmin current={<Outlet />} />}
          >
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
          </Route>

          <Route
            path={admin_prefix}
            element={<AdminLayout current={<Outlet />} />}
          >
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard module_id={true} />} />
            <Route path="admin-attendance-report" element={<AdminMonthlyAttendance module_id={true} />} />
            <Route path="profile" element={<Profile module_id={true} />} />
            <Route
              path="my-notifications"
              element={<MyNotifications module_id={true} />}
            />
            <Route path="logout" element={<Logout module_id={true} />} />

            <Route path="user-roles" foo="bar" element={<RolesList />} />
            <Route path="permission/:slug" element={<RolePermission />} />
            <Route path="users" element={<UsersList />} />

            <Route path="cms-pages" element={<CmsList />} />
            <Route path="cms-pages/:slug" element={<UpdateCms />} />
            <Route path="banners" element={<BannersList />} />

            <Route path="products" element={<ProductList />} />

            <Route path="products/add" element={<AddProduct />} />
            <Route path="products/edit/:slug" element={<EditProduct />} />

            <Route
              path="application-setting/:key"
              element={<GeneralSettings />}
            />
            {/* <Route path="backup-database" element={<BackupDatabase />} /> */}
            <Route path="country" element={<CountryList />} />
            <Route path="state" element={<StateList />} />
            <Route path="region" element={<RegionList />} />
            <Route path="zone" element={<ZoneList />} />
            <Route path="district" element={<CityList />} />
            <Route path="town" element={<AreaList />} />
            <Route path="employee" element={<EmployeeList />} />
            <Route path="sales-supervisor" element={<EmployeeList />} />
            <Route path="assistant-sub-manager" element={<EmployeeList />} />
            <Route path="regional-sub-manager" element={<EmployeeList />} />
            <Route path="general-manager" element={<EmployeeList />} />
            <Route path="manager-director" element={<EmployeeList />} />
            <Route path="employee/add" element={<EmployeeAdd />} />
            <Route path="employee/edit/:employeeId" element={<EmployeeAdd />} />
            <Route path="distributor" element={<DistributorList />} />
            <Route path="distributor/add" element={<DistributorAdd />} />
            <Route
              path="distributor/edit/:distributorId"
              element={<DistributorAdd />}
            />
            <Route path="attendance-status" element={<AttendanceStatus />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="monthly-attendance" element={<MonthlyAttendance />} />
            <Route
              path="distributor-purchase"
              element={<DistributorPurchaseList />}
            />
            <Route path="distributor-order" element={<DistributorOrder />} />
            <Route
              path="distributor-order/:orderId"
              element={<DistributorOrder />}
            />
            <Route
              path="employee-purchase"
              element={<EmployeePurchaseList />}
            />
            <Route path="employee-sales" element={<EmployeeSalesList />} />
            <Route path="closing-stock" element={<ClosingStockList />} />
            <Route path="units" element={<Units />} />
          </Route>

          <Route path={`${admin_prefix}/*`} element={<Error404 />} />
          <Route path="*" element={<></>} />
        </Routes>
      </Suspense>
    </>
  );
}
