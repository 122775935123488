import { matchRoutes, useLocation } from "react-router-dom";
const admin_prefix = process.env.REACT_APP_ADMIN_ROUTE_PREFIX;

const routes = [
  // Admin Without Permission
  { path: admin_prefix, module_id: true },
  { path: admin_prefix + "/", module_id: true },
  { path: admin_prefix + "/dashboard", module_id: true },
  { path: admin_prefix + "/profile", module_id: true },
  { path: admin_prefix + "/my-notifications", module_id: true },
  { path: admin_prefix + "/logout", module_id: true },

  // Admin With Permission
  { path: admin_prefix + "/user-roles", module_id: 101 },
  { path: admin_prefix + "/permission/:slug", module_id: 105 },
  { path: admin_prefix + "/users", module_id: 401 },

  { path: admin_prefix + "/cms-pages", module_id: 2001 },
  { path: admin_prefix + "/cms-pages/:slug", module_id: 2001 },
  { path: admin_prefix + "/banners", module_id: 701 },
  { path: admin_prefix + "/products", module_id: 601 },
  { path: admin_prefix + "/products/add", module_id: 602 },
  { path: admin_prefix + "/products/edit/:slug", module_id: 603 },
  { path: admin_prefix + "/notifications", module_id: 2101 },
  {
    path: admin_prefix + "/application-setting/:key",
    module_id: [2301, 2401, 2501, 2601, 2701, 2801],
  },

  { path: admin_prefix + "/country", module_id: 901 },
  { path: admin_prefix + "/state", module_id: 1001 },
  { path: admin_prefix + "/district", module_id: 1101 },
  { path: admin_prefix + "/town", module_id: 1201 },
  { path: admin_prefix + "/employee", module_id: 201 },
  { path: admin_prefix + "/sales-supervisor", module_id: 201 },
  { path: admin_prefix + "/assistant-sub-manager", module_id: 201 },
  { path: admin_prefix + "/regional-sub-manager", module_id: 201 },
  { path: admin_prefix + "/general-manager", module_id: 201 },
  { path: admin_prefix + "/manager-director", module_id: 201 },
  { path: admin_prefix + "/employee/add", module_id: 202 },
  { path: admin_prefix + "/employee/edit/:employeeId", module_id: 203 },
  { path: admin_prefix + "/distributor", module_id: 801 },
  { path: admin_prefix + "/distributor/add", module_id: 802 },
  { path: admin_prefix + "/distributor/edit/:distributorId", module_id: 803 },
  { path: admin_prefix + "/attendance-status", module_id: 301 },
  { path: admin_prefix + "/attendance", module_id: 1301 },
  { path: admin_prefix + "/monthly-attendance", module_id: 1301 },
  { path: admin_prefix + "/distributor-purchase", module_id: 1401 },
  { path: admin_prefix + "/distributor-order", module_id: 1402 },
  { path: admin_prefix + "/distributor-order/:orderId", module_id: 1403 },
  { path: admin_prefix + "/employee-purchase", module_id: 1501 },
  { path: admin_prefix + "/employee-sales", module_id: 1601 },
  { path: admin_prefix + "/closing-stock", module_id: 1701 },
  { path: admin_prefix + "/units", module_id: 601 },
  { path: admin_prefix + "/region", module_id: 1001 },
  { path: admin_prefix + "/zone", module_id: 1001 },
  { path: admin_prefix + "/admin-attendance-report", module_id: true },
];

const routes_admin_open = [
  { path: admin_prefix + "/login", module_id: true },
  { path: admin_prefix + "/forgot-password", module_id: true },
  { path: admin_prefix + "/reset-password/:token", module_id: true },
  { path: admin_prefix + "/*", module_id: true },
];

const routes_open = [
  { path: "/", module_id: true },
  { path: "/home", module_id: true },
  { path: "/about", module_id: true },
  { path: "/contact-us", module_id: true },
  { path: "/our-services", module_id: true },
  { path: "/privacy-policy", module_id: true },
  { path: "/terms-conditions", module_id: true },
  { path: "/delivery-information", module_id: true },
  { path: "/returns-exchanges", module_id: true },
];

const useCurrentPath = (types = ["admin"]) => {
  var array_merge = [];
  const location = useLocation();
  if (types.includes("admin")) {
    array_merge = array_merge.concat(routes);
  }

  if (types.includes("admin_open")) {
    array_merge = array_merge.concat(routes_admin_open);
  }

  if (types.includes("open")) {
    array_merge = array_merge.concat(routes_open);
  }

  var data = matchRoutes(array_merge, location);
  if (data) {
    var [{ route }] = data;
  }
  return data ? route : null;
};

export default useCurrentPath;
