import { createContext } from "react";
import { toast } from "react-toastify";
import AxiosHelper from '../Helpers/AxiosHelper'

const addToCart = async (product_id, qty = 1, extraData = {}) => {
    var { data } = await AxiosHelper.postData(`/users/cart`, { product_id, qty, ...extraData });
    if (data?.status === true) {
        toast.success('Product added to cart successfully.')
        return true;
    } else {
        toast.error(data.message);
        return false;
    }
}

const updateCart = async (updatedData, id) => {
    var { data } = await AxiosHelper.putData(`/users/cart?id=${id}`, updatedData);
    if (data?.status === true) {
        return true;
    }
    return false;
}

const removeItemFromCart = async (id) => {
    var { data } = await AxiosHelper.deleteData(`/users/cart/${id}`);
    if (data?.status === true) {
        return true;
    }
    return false;
}

const userData = { addToCart, updateCart, removeItemFromCart }

const CartProvider = (props) => {

    return (
        <cartData.Provider value={userData}>
            {props.children}
        </cartData.Provider>
    )
}

export const cartData = createContext(userData)
export default CartProvider